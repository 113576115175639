.btn1 {
  color: #fff;
  padding: 0.7rem;
  font-weight: 300;
  transition: all 0.7s ease;
  cursor: pointer;
  font-size: 0.875rem;
  i {
    margin-right: 3px;
  }
  &:hover {
    transform: translateY(-4px);
  }
}

@media screen and (min-width: 1200px) {
  .signin-btn{
    padding: 0.7rem 1rem;
  }
}
