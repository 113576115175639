.featured-project {
  background-color: var(--fifthColor);
  .featured-project-heading {
    margin-bottom: 1rem;
    p {
      color: var(--primary);
      text-align: center;
      margin: auto;
      font-size: 14px;
    }
  }
  .featured-project-properties-list {
    .featured-project-property {
      margin-bottom: 10px;
      position: relative;
      padding-bottom: 20px;
      background-color: #fff;
      transition: all 0.7s ease;

      &:hover {
        box-shadow: 0px 0px 9px var(--shadow);
        transform: scale(1.05);
      }

      .featured-project-property-description {
        background-color: #fff;
        padding: 12px 15px;
        position: relative;

        h5 {
          font-size: 14px;
          line-height: 14px;
          margin-bottom: 5px;
        }
        p {
          font-size: 12px;
          margin-bottom: 0;
          color: var(--primary);
          i {
            margin-right: 3px;
          }
        }
        .p-status {
          width: auto;
          height: 36px;
          padding-left: 10px;
          padding-right: 10px;
          position: absolute;
          right: 0;
          top: 14px;
          background-color: var(--fourthColor);

          .status {
            font-size: 13px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            span {
              margin-right: 5px;

              &.bullet-icon {
                position: relative;
                top: -1px;

                i {
                  font-size: 10px;
                }
              }
            }
          }

          &::after {
            width: auto;
            height: auto;
            content: "";
            border-bottom: 36px solid var(--fourthColor);
            border-right: 0px solid transparent;
            border-left: 30px solid transparent;
            position: absolute;
            top: 0px;
            right: 100%;
          }

          &.live-status {
            background-color: var(--secondary);

            &::after {
              border-bottom-color: var(--secondary);
            }
          }

          &.closed-status {
            background-color: var(--seventhColor);

            &::after {
              border-bottom-color: var(--seventhColor);
            }
          }
        }
      }

      .featured-project-property-img {
        width: 100%;
        height: 180px;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }

        .invest-amt {
          width: 50%;
          background-color: #fff;
          padding: 10px 15px;
          position: absolute;
          left: 0;
          bottom: 0;
          border-radius: 0 4px 0 0;
          background-color: rgba(0, 0, 0, 0.7);

          h6 {
            font-size: 13px;
            color: #fff;
            position: relative;
            top: 0;
            margin-bottom: 0;
          }
        }
      }

      .featured-project-property-specification {
        width: 100%;

        .featured-project-p-short-desc {
          background-color: var(--fourthColor);
          padding: 10px 15px;

          p {
            font-size: 12px;
            margin-bottom: 0px;
            line-height: 18px;
          }
        }

        .featured-project-p-spec {
          padding: 15px;
          padding-top: 0;

          ul {
            width: 100%;
            list-style: none;
            padding: 0;
            margin-bottom: 0;
            padding-top: 2px;

            li {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 4px 0;
              font-size: 13px;
              border-bottom: 1px solid transparent;

              .spec-name {
                color: var(--primary);
              }

              .spec-qty {
                color: var(--primary);
              }
            }
            &.list-bg-color {
              li {
                padding-left: 10px;
                padding-right: 10px;

                &:nth-child(even) {
                  background-color: var(--fourthColor);
                }
              }
            }
          }
        }

        .btn-view-details {
          text-align: center;

          .btn-moreinfo {
            width: calc(100% - 30px);
            display: inline-block;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: var(--primary);
            border: 1px solid var(--primary);
            font-size: 14px;
          }
        }
        .featured-project-button {
          padding: 0 15px;
        }
      }
    }
  }
}
