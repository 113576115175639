.inv-cont {
  background-color: var(--fourthColor);
  .content {
    border-left: 4px solid var(--secondary);
    padding: 0 0 0 1rem;
    h5 {
      color: var(--primary);
      font-size: 18px;
      line-height: 2rem;
    }
    p {
      color: var(--primary);
      font-size: 14px;
      font-weight: 400;
      margin: 0;
    }
  }
}

.client-say {
  background-image: url(../../../assets/images/testimonial-bg.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  .main-contain {
    .client-text {
      color: white;
      font-style: italic;
      p {
        font-size: 15px;
        font-weight: 400;
      }
      h6{
        text-align: end;
        font-size: 13px;
      }
    }
  }
}
