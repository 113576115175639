.three-blocks {
  .investor-blocks {
    display: flex;
    flex-direction: column;
    .card-title {
      padding: 0.8rem 1rem;
      h5 {
        margin: 0;
        color: white;
        font-size: 1rem;
        text-align: center;
      }
    }
    .card-img {
      height: 215px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .cards-contents {
      background-color: var(--fourthColor);
      padding: 1rem;
      flex: 1;
      p {
        font-size: 0.875rem;
        margin: 0;
        line-height: 1.375rem;
        text-align: justify;
      }
    }
  }
}

img {
  width: 215px;
  display: block;
  margin: 0 auto;
}
