.LoginRegister {
  .form-check {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
  }
  .form-check {
    a {
      color: var(--primary);
      text-decoration: underline;
    }
  }
}
.main-btn-block {
  margin-top: 1rem;
  .registration-block {
    margin-top: 1rem;
    a {
      color: var(--secondary);
      text-decoration: underline;
    }
  }
}

@media screen and (min-width: 768px) {
  .main-btn-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .registration-block {
      margin-top: 0rem !important;
    }
  }
}
