.investmentApproach{
    background-color: var(--fourthColor);
    p{
        font-size: 0.875rem;
        position: relative;
        padding: 1rem 0 1rem 0;
        &::before {
            width: 60px;
            height: 3px;
            content: "";
            background-color: var(--secondary);
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}