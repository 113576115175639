.whyinv-cont1 {
  background-color: var(--fourthColor);
  .content {
    border-left: 4px solid var(--secondary);
    padding: 0 0 0 1rem;
    h5 {
      color: var(--primary);
      font-size: 18px;
      line-height: 2rem;
    }
    ul{
      margin: 0;
    }
    p,
    li {
      color: var(--primary);
      font-size: 14px;
      font-weight: 400;
      margin: 0;
    }
  }
}

.whyinv-blocks {
  .investor-blocks {
    display: flex;
    flex-direction: column;
    .card-title {
      padding: 0.8rem 1rem;
      h5 {
        margin: 0;
        color: white;
        font-size: 1rem;
        text-align: center;
      }
    }
    .card-img {
      height: 215px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .cards-contents {
      background-color: var(--fourthColor);
      padding: 1rem;
      flex: 1;
      p {
        font-size: 0.875rem;
        margin: 0;
        line-height: 1.375rem;
        text-align: justify;
      }
    }
  }
}
.leading-project {
  h4 {
    color: var(--secondary);
    font-weight: 600;
  }
  p {
    color: var(--primary);
    font-weight: 400;
  }
}
