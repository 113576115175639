.page-banner {
  .banner {
    width: 100%;
    height: 65vh;
    background-position: center;
    background-size: cover;
    border-bottom: 1px solid var(--eighthColor);
  }
  .banner-text {
    text-align: center;
    h4 {
      color: var(--secondary);
      text-transform: uppercase;
      font-weight: 600;
    }
    p {
      color: var(--primary);
      line-height: 24px;
    }
    .border-orange-line {
      background-color: var(--secondary);
      height: 3px;
      margin: 25px auto auto;
      width: 100px;
    }
  }
}
