@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "react-responsive-carousel/lib/styles/carousel.css";
@import "react-tabs/style/react-tabs.scss";

:root {
  --primary: #183a65;
  --secondary: #ea631d;
  --shadow: rgba(24, 58, 101, 0.3);
  --thirdColor: #242424;
  --fourthColor: #f2f2f2;
  --fifthColor: #e6e9ee;
  --sixthColor: #dadee3;
  --seventhColor: #576373;
  --eighthColor: #98a9bf;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-family: "Poppins", sans-serif;
}
body {
  text-align: left;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

.pageHeight {
  height: calc(100vh - 5rem);
}

.authPageHeight {
  min-height: calc(100vh - 6.8125rem);
}

.boxShadow {
  box-shadow: 0px 0px 9px var(--shadow);
}

.topicHeading {
  color: var(--primary);
  font-size: 2rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 600;
}

.smHeading {
  font-size: 18px;
  color: var(--secondary);
  margin: auto;
  line-height: 30px;
  margin-bottom: 5px;
}

.colorSecondary {
  color: var(--secondary);
}

.cursorPointer {
  cursor: pointer;
}

.sectionBottomBorder {
  border-bottom: 1px dashed var(--secondary);
}

.sectionPaddingY {
  padding: 1.5625rem 0;
}

.container-inn {
  margin: auto;
  width: 100%;
  max-width: 900px;
}

@media screen and (max-width: 768px) {
  .sectionPaddingY {
    padding: 1.5rem 0;
  }
  .topicHeading {
    font-size: 1.5rem;
    line-height: normal;
    margin-bottom: 10px;
  }
  .smHeading {
    font-size: 13px;
    line-height: normal;
    margin-bottom: 5px;
  }
}

a{
  text-decoration: none !important;
}
