.project-details {
    display: none;
}
.innerpage-contents {
    .fill-submit-invest {
        background-color: #f2f2f2;
        padding: 15px;
        border: 1px solid #e0e0e0;
        border-bottom: 3px solid #183a65;
        position: relative;
        margin-top: 45px;

        .property-name {
            padding-top: 14px;
            border-right: 1px dotted #767676;
            background-color: #183a65;
            position: absolute;
            top: -45px;
            left: -1px;
            padding: 10px 15px;
            display: flex;

            h5 {
                font-size: 20px;
                margin: 0;
                line-height: 18px;
                text-transform: uppercase;
                color: #f2f2f2;
                margin-right: 15px;
                line-height: 24px;
            }

            h6 {
                font-size: 12px;
                margin: 0;
                color: #a5c4eb;
                text-transform: uppercase;
                position: relative;
                top: 3px;
            }
        }

        .property-target {
            position: relative;
            padding-right: 140px;

            .form-group {
                font-size: 18px;
                font-weight: 600;
                color: #183a65;
                margin-bottom: 0;
                margin-top: 5px;
                margin-bottom: 5px;
                text-align: left;

                label {
                    width: 100%;
                    display: block;
                    font-size: 13px;
                    color: #183a65;
                    margin-bottom: 10px;
                    line-height: 16px;
                }

                .target-text {
                    font-size: 16px;
                    font-weight: 600;
                    color: #eb641e;
                    margin-bottom: 0;
                    margin-top: 6px;
                }

                .form-control {
                    border-radius: 0;
                    font-size: 13px;
                }
            }

            .invest-btnblock {
                width: auto;
                height: auto;
                position: absolute;
                right: 0px;
                top: 0;
                bottom: 0;
                display: flex;
                align-items: center;

                .btn-invest {
                    width: 100%;
                    font-size: 14px;
                    padding: 10px 15px;
                }
            }
        }
    }

    .propety-img-carousel {
        max-width: 100%;
        margin: auto;
        position: relative;
        margin-top: 0;
        margin-bottom: 20px;

        .carousel-root {
            .carousel-slider {
                .control-dots {
                    display: none;
                }

                .control-arrow {
                    &.control-prev {
                        background-color: #242424;
                    }

                    &.control-next {
                        background-color: #242424;
                    }
                }
            }

            .carousel {
                .thumbs-wrapper {
                    width: 100%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    padding: 14px 10px 7px 10px;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.6);

                    ul.thumbs {
                        padding-left: 0;
                        text-align: center;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .cl-project-summery {
        margin-top: 20px;

        .ps-left {
            &.custom-tabs {
                .react-tabs {
                    .react-tabs__tab-list {
                        display: flex;

                        li {
                            font-size: 13px;
                            white-space: normal;
                            width: 16.666%;
                            text-align: center;
                            display: flex;
                            height: 70px;
                            justify-content: center;
                            align-items: center;
                            background-color: #183a65;
                            color: #fff;
                            border: none;
                            line-height: 16px;
                            background: rgb(24, 58, 101);
                            background: linear-gradient(90deg, rgba(24, 58, 101, 1) 0%, rgba(22, 70, 116, 1) 0%, rgba(10, 146, 210, 1) 100%, rgba(7, 161, 229, 1) 100%, rgba(3, 182, 255, 1) 100%);

                            &.react-tabs__tab--selected {
                                background: rgb(180, 71, 14);
                                background: linear-gradient(180deg, rgba(180, 71, 14, 1) 0%, rgba(235, 100, 30, 1) 16%, rgba(235, 100, 30, 1) 100%);
                            }
                        }
                    }
                }

                .cl-tabpanel {
                    padding: 15px;
                    display: none;

                    &.react-tabs__tab-panel--selected {
                        display: block;
                    }

                    .summery-table {
                        thead {
                            tr {
                                th {
                                    font-size: 13px;
                                    line-height: 18px;
                                    vertical-align: middle;
                                }
                            }
                        }

                        tbody {
                            tr {
                                &:nth-child(even) {
                                    background-color: #f2f2f2;
                                }

                                td {
                                    font-size: 13px;
                                    font-weight: normal;
                                    vertical-align: middle;

                                    &:first-child {
                                        width: 40%;
                                        font-weight: 600;
                                    }
                                }
                            }
                        }

                        &.review-table {
                            thead {
                                tr {
                                    th {
                                        width: 35%;

                                        &:first-child {
                                            width: 20%;
                                        }

                                        &:last-child {
                                            width: 10%;
                                        }
                                    }
                                }
                            }

                            tbody {
                                tr {
                                    td {
                                        width: 35%;

                                        &:first-child {
                                            width: 20%;
                                            font-weight: 600;
                                        }

                                        &:last-child {
                                            width: 10%;
                                            text-align: center;
                                        }
                                    }
                                }
                            }
                        }

                        &.riskfactor-table {
                            tr {
                                td {
                                    vertical-align: middle;

                                    &:first-child {
                                        width: 30%;
                                        font-weight: 600;
                                    }

                                    &:last-child {
                                        width: 70%;
                                    }
                                }
                            }
                        }
                    }

                    h6 {
                        color: #183a65;
                    }

                    .fw-normal {
                        font-weight: normal;
                    }
                }
            }
        }

        .ps-right {
            position: sticky;
            position: -webkit-sticky;
            position: -moz-sticky;
            top: 100px;

            .card {
                margin-bottom: 15px;
                overflow: hidden;

                .card-title {
                    padding: 10px 15px;
                    line-height: 20px;
                    margin-bottom: 0;
                    background-color: #f2f2f2;
                    border-bottom: 1px solid #e0e0e0;

                    h6 {
                        margin-bottom: 0;
                        color: #183a65;
                    }
                }

                .card-body {
                    ul {
                        margin-bottom: 0;
                        padding-left: 15px;

                        li {
                            font-size: 14px;
                            font-weight: 500;
                            margin-bottom: 5px;
                        }
                    }
                    table {
                        tr {
                            td {
                                font-size: 13px;
                                padding: 5px 10px;
                                &:first-child {
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}