.ir-block {
  padding: 2rem 2.5rem;
  .ir-img-block {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    img {
      width: 80px;
    }
  }
  h5 {
    font-size: 1.125rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
  }
  p {
    font-size: 0.875rem;
    font-weight: 300;
    margin: 0;
  }
}
