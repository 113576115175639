.icon-block {
  display: flex;
  .icon-block-icon {
    margin-right: 1rem;
    .icon-block-img {
      width: 60px;
      img {
        width: 100%;
      }
    }
  }
  .icon-block-container {
    h5 {
      color: var(--primary);
      font-weight: 600;
    }
    p {
      font-size: 14px;
      text-align: justify;
    }
  }
}

.explore-opportunities-section {
  .container-inn {
    .container-inn-heading {
      border-bottom: 1px dashed var(--eighthColor);
      color: var(--primary);
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      margin-bottom: 30px;
      padding-bottom: 20px;
      text-align: center;
    }
    h4 {
      color: var(--primary);
      font-size: 26px;
      line-height: 30px;
      margin: auto auto 30px;
      max-width: 100%;
      text-align: center;
      font-weight: 600;
    }
    .expopp-block {
      .expopp-block-inn {
        margin-right: 2rem;
        position: relative;
        h3 {
          &::before {
            content: "";
            width: 20px;
            height: 6px;
            background-color: var(--secondary);
            border-radius: 4px;
            position: absolute;
            top: -20px;
            left: 0;
          }

          &::after {
            content: "";
            width: 80px;
            height: 6px;
            background-color: var(--secondary);
            border-radius: 4px;
            position: absolute;
            top: -20px;
            left: 25px;
          }
        }
        p {
          color: var(--thirdColor);
          font-size: 15px;
          line-height: 22px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.why-capitaleta {
  background-color: var(--fourthColor);
  .whyInv-right-block {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 88%;
    }
  }
  .whyInv-left-block {
    h6 {
      font-size: 13px;
      font-weight: 400;
      font-style: italic;
      color: var(--secondary);
      margin-bottom: 1rem;
    }
    .whyInv-accordian {
      .accordion {
        border: none;
        .accordion__item {
          margin-bottom: 1rem;
          border: none;

          &:first-child {
            .accordion__panel {
              display: block;
            }
          }

          .accordion__heading {
            transition: all 0.7s ease;
            box-shadow: 0px 0px 9px var(--shadow);

            .accordion__button {
              font-size: 14px;
              font-weight: 500;
              background-color: #ffffff;
              color: #242424;
              transition: all 0.7s ease;

              &:hover {
                background-color: #eb641e;
                color: #fff;
              }
            }

            .accordion__button:before {
              height: 8px;
              width: 8px;
              position: relative;
              top: -2px;
            }

            .accordion__button[aria-expanded="true"] {
              background-color: #eb641e;
              color: #fff;
            }

            .accordion__button[aria-expanded="true"]::before {
              transition: all 0.8s ease;
            }
          }

          .accordion__panel {
            background-color: #fff;

            p {
              font-size: 13px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}



.submit-your-project {
  .video-block {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../../assets/images/video-img.png);
    background-position: center;
    background-size: cover;
    img {
      width: 60%;
    }
  }
}

.raise-capital {
  background-color: var(--fifthColor);
  img {
    width: 88%;
  }

  ul {
    list-style: none;
    margin: 15px 0 30px;

    li {
      position: relative;
      font-size: 14px;
      margin-bottom: 15px;
      color: var(--thirdColor);

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        width: 7px;
        height: 7px;
        content: "";
        border-top: 1.5px solid var(--thirdColor);
        border-right: 1.5px solid var(--thirdColor);
        position: absolute;
        left: -15px;
        top: 8px;
        transform: rotate(45deg);
      }
    }
  }
}



@media screen and (max-width: 768px) {
  .explore-opportunities-section {
    .container-inn {
      .container-inn-heading {
        font-size: 1rem;
        line-height: normal;
        margin-bottom: 15px;
        padding-bottom: 10px;
      }
      h4 {
        font-size: 1.2rem;
        line-height: normal;
        margin: auto auto 15px;
      }
      .expopp-block {
        .expopp-block-inn {
          margin: 2rem 0;
          h3 {
            font-size: 1.5rem;
            line-height: normal;
            margin-bottom: 10px;
          }
          p {
            font-size: 13px;
            line-height: normal;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
