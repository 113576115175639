.prp-steps {
  position: relative;
  &::after {
    width: 2px;
    height: 100%;
    content: "";
    background-color: var(--primary);
    position: absolute;
    right: 50%;
    top: 0;
    transform: translateX(1px);
  }
  .prp-steps-img-block {
    padding: 1rem 2rem;
    .prp-steps-img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 350px;
      height: 100%;
    }
  }
  .prp-steps-text {
    padding: 1rem 2rem;
    h5 {
      color: var(--primary);
      margin-bottom: 1rem;
      font-weight: 600;
    }
    h6 {
      color: var(--secondary);
    }
    p {
      color: var(--primary);
      margin-bottom: 1rem;
      font-size: 0.875rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 992px) {
  .prp-steps {
    &:after {
      content: none;
    }
  }
}

.secondaryColorSection {
  background-color: var(--secondary);
  p {
    font-size: 0.8rem;
    margin: 0;
    color: white;
    text-align: center;
  }
}
