.inv-cont {
  background-color: var(--primary);
  .content {
    border-left: 4px solid var(--secondary);
    padding: 0 0 0 1rem;
    h5 {
      color: var(--secondary);
      font-size: 18px;
      line-height: 2rem;
    }
    p {
      color: white;
      font-size: 14px;
      font-weight: 400;
      margin: 0;
    }
  }
}
.hiw-row-block {
  background-color: var(--fourthColor);
  padding: 2rem;
  .hiw-contents {
    h4 {
      font-size: 1.875rem;
      color: var(--primary);
    }
    p {
      color: var(--primary);
    }
  }
  .hiw-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: auto;
      width: 100px;
    }
  }
}

.project-onboarding {
  background-color: var(--fourthColor);
  .midHeading {
    font-size: 1.375rem;
    font-weight: 700;
    color: var(--primary);
  }
  .project-onboarding-block {
    color: white;
    padding: 1rem;
    h5 {
      font-size: 1.125rem;
      font-weight: 600;
      margin-top: 1rem;
    }
    p {
      font-size: 0.875rem;
    }
  }
}

.leftBorder {
  border-left: 2px solid var(--primary);
}

.rightBorder {
  border-right: 2px solid var(--primary);
}
