.latest-news-and-insights {
  background-color: var(--fourthColor);
  .news-blocks {
    margin-top: 30px;

    .news-blocks-inn {
      text-align: left;
      background-color: #fff;

      .imgblock {
        position: relative;
        min-height: 200px;

        img {
          max-width: 100%;
          width: 100%;
          height: 40vh;
        }
        .read-more {
          display: flex;
          justify-content: flex-end;
          position: absolute;
          bottom: 30px;
          right: 15px;
        }
      }

      .news-content {
        padding: 20px 20px 20px 20px;
        min-height: 200px;

        h6 {
          font-size: 11px;
          color: var(--eighthColor);
          font-weight: normal;
          margin-bottom: 0;
          text-transform: none;
          margin-bottom: 10px;

          span {
            margin-right: 15px;

            i {
              color: var(--secondary);
            }

            &.cl-user {
              i {
                font-size: 14px;
              }
            }

            &.cl-date {
              i {
                margin-right: 3px;
              }
            }
          }
        }

        h5 {
          font-size: 16px;
          font-weight: 600;
          color: #242424;
          line-height: 20px;

          a {
            color: var(--primary);
            transition: all 0.7s ease;

            &:hover {
              color: var(--secondary);
            }
          }
        }

        p {
          font-size: 12px;
          margin-bottom: 0;
        }
      }
    }
  }
}
