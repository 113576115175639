.sti-tabs-content {
  .sti-img-block {
    height: 250px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

  }
  .react-tabs {
    .react-tabs__tab-list {
      border-bottom: 2px solid var(--primary);
      margin-bottom: 15px;

      .react-tabs__tab {
        border: 1px solid var(--eighthColor);
        border-radius: 3px 3px 0 0;
        border-bottom: none;
        margin-right: 4px;
        bottom: 5px !important;
        margin-bottom: 1px !important;
      }

      .react-tabs__tab--selected {
        background-color: var(--secondary);
        color: #fff;
        border: 1px solid transparent;
        bottom: 5px !important;
        margin-bottom: 1px !important;
      }
    }

    .react-tabs__tab-panel {
      h5 {
        font-size: 20px;
        color: var(--primary);
        line-height: 1rem;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      p {
        font-size: 13px;
        font-weight: 300;
        color: var(--primary);
        margin-bottom: 1rem;
      }
    }
  }

  &.right-align {
    .react-tabs {
      .react-tabs__tab-list {
        width: 100%;
        text-align: right;
        display: inline-block;
      }

      .react-tabs__tab-panel {
        h5 {
          font-size: 20px;
          color: var(--primary);
          line-height: 16px;
          text-transform: uppercase;
          margin-bottom: 10px;
          text-align: right;
        }

        p {
          font-size: 13px;
          font-weight: 300;
          color: var(--primary);
          text-align: right;
          margin-bottom: 15px;
        }
      }
    }
  }
}
