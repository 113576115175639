.mainContainer {
  background-color: var(--fourthColor);
  .faq_details {
    width: 900px;
    margin: 0 auto;
    max-width: 100%;
    background-color: #fff;
    position: relative;
    margin-top: 0;
    padding-top: 15px;
    border-radius: 4px;

    .custom-tabs {
      .react-tabs {
        .react-tabs__tab-list {
          display: flex;
          border: none;
          width: 100%;
          justify-content: space-around;
          border-bottom: 1px solid var(--eighthColor);
          margin-bottom: 0;

          li {
            width: 20%;
            font-size: 14px;
            text-align: center;
            border: none;
            color: var(--eighthColor);

            &.react-tabs__tab {
              padding-bottom: 10px;

              img {
                width: 40px;
                height: auto;
                filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
                filter: var(--eighthColor);
                -webkit-filter: grayscale(100%);
              }
              p {
                text-align: center;
                display: block;
                margin-bottom: 5px;
                font-size: 8px;
                @media screen and (min-width: 768px) {
                  font-size: 13px;
                }
              }

              &.react-tabs__tab--selected {
                color: var(--secondary);
                margin: 0;
                border-bottom: 2px solid var(--secondary) !important;

                img {
                  filter: none;
                  -webkit-filter: grayscale(0%);
                }
              }

              &::after {
                display: none;
              }
            }
          }
        }

        .cl-tabpanel {
          padding: 15px;
          display: none;

          &.react-tabs__tab-panel--selected {
            display: block;

            h4 {
              font-size: 25px;
            }
          }

          .accordion {
            border: none;
            .accordion__item {
              margin-bottom: 1rem;
              border: none;

              &:first-child {
                .accordion__panel {
                  display: block;
                }
              }

              .accordion__heading {
                transition: all 0.7s ease;
                .accordion__button {
                  font-size: 14px;
                  font-weight: 500;
                  background-color: var(--fourthColor);
                  color: var(--thirdColor);
                  transition: all 0.7s ease;

                  &:hover {
                    background-color: var(--secondary);
                    color: #fff;
                  }
                }

                .accordion__button:before {
                  height: 8px;
                  width: 8px;
                  position: relative;
                  top: -2px;
                }

                .accordion__button[aria-expanded="true"] {
                  background-color: var(--secondary);
                  color: #fff;
                }

                .accordion__button[aria-expanded="true"]::before {
                  transition: all 0.8s ease;
                }
              }

              .accordion__panel {
                background-color: #fff;

                ul {
                  font-size: 13px;
                  font-weight: normal;

                  li {
                    font-size: 13px;
                    font-weight: normal;
                    margin-bottom: 10px;
                  }
                }

                ol {
                  font-size: 13px;

                  li {
                    font-size: 13px;
                    font-weight: normal;
                    margin-bottom: 10px;
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
                h6 {
                  font-size: 13px;
                  text-transform: capitalize;
                }
                p {
                  font-size: 13px;
                  font-weight: normal;
                  margin-bottom: 5px;
                  margin-top: 5px;
                  &:last-child {
                    margin-bottom: 0p;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
