.kycLeftPart {
  border-right: 2px solid var(--secondary);
  .userName {
    // text-overflow: ellipsis;
    line-height: 1em;
    font-size: 2rem;
  }
  .leftPartMenuPart {
    margin-top: 2.5rem;
    .leftPartMenu {
      list-style-type: none;
      li {
        .kycSidenavItem {
          font-size: 14px;
          cursor: pointer;
          padding: 0.75rem 1rem;
          border-left: 2px solid transparent;
          transition: 0.15s all ease;
          color: var(--primary);
          transition: 0.3s all ease;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          &:hover {
            color: var(--secondary);
            font-size: 16px;
            font-weight: 500;
          }
          &.active {
            border-left-color: var(--primary);
            color: var(--secondary);
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
  }
}



