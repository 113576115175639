.home-banner {
  width: 100%;
  height: 65vh;
  background-image: url(../../assets/images/home-banner.jpg);
  background-position: center;
  background-size: cover;
  border-bottom: 1px solid #ecdbd7;
  .banner-content {
    padding: 0 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    h1 {
      font-size: 3.2rem;
      font-weight: 700;
      color: var(--primary);
    }
    .home-banner-tag {
      font-size: 1.4rem;
      font-weight: 500;
      color: var(--secondary);
      margin-bottom: 0.5rem;
    }
    .banner-button {
      margin-top: 1.5rem;
      display: flex;
      gap: 1rem;
    }
    .banner-bottom-text {
      margin-top: 1rem;
      font-weight: 600;
      font-size: 14px;
      a {
        color: var(--secondary);
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .home-banner {
    height: calc(100vh - 5rem);
  }
}

@media screen and (max-width: 768px) {
  .home-banner {
    .banner-content {
      h1 {
        font-size: 2rem;
      }
      .banner-button {
        flex-direction: column;
      }
      .home-banner-tag {
        font-size: 1rem;
      }
    }
  }
}
