.leftBorderBlock {
  padding-left: 2rem;
  position: relative;
  &::before {
    width: 3px;
    height: 100%;
    background-color: var(--secondary);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
  }
}

.rightBorderBlock {
  padding-right: 2rem;
  position: relative;
  &::before {
    width: 3px;
    height: 100%;
    background-color: var(--secondary);
    content: "";
    position: absolute;
    right: 0;
    top: 0;
  }
}

p {
  font-size: 13px;
  margin: 0;
}
