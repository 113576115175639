.deshboardSection {
  .setUp {
    .singleStep {
      cursor: pointer;
      .stepNo {
        color: var(--secondary);
        font-size: 0.75rem;
      }
      .stepText {
        color: var(--primary);
      }
      &:hover {
        .stepText {
          opacity: 0.7;
        }
      }
    }
  }

  .textColor {
    color: var(--secondary) !important;
  }

  .module {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .deshCommon-btn {
      height: 3em;
      border-color: var(--secondary) !important;
    }
    .makeChange {
      color: var(--secondary);
      background-color: #fff;
    }
    .inviteNow {
      color: #fff;
      background-color: var(--secondary);
    }
  }
  .cardContainer {
    position: relative;
    .profileCard {
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
        rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
        rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
      position: sticky;
      top: 80px;

      .userCircle {
        width: 100px;
        height: 100px;
        letter-spacing: 1.5px;
        background-color: var(--secondary);
        .userShortName {
          color: #fff;
          font-size: 36px;
        }
      }
    }
  }

  .fundsIcon {
    width: 2rem;
    height: 2rem;
    background-color: rgb(240, 238, 248);
  }
  .accountItems:last-child {
    li {
      color: var(--secondary) !important;
    }
  }
}
.walletPart {
  background-color: var(--fifthColor);
  .walletCard {
    .walletIcon {
      background-color: var(--sixthColor);
      cursor: pointer;
    }
    .walletBal {
      color: white;
      .walletAmtSign {
        line-height: 2.5rem;
      }
    }
  }
}

.portfolioPart {
  .total {
    border: 1px solid var(--secondary);
  }
  .portfolioBox {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    height: 20rem;
  }
}
