header {
  background-color: white;
  .main-header {
    padding: 0 1rem;
    height: 5rem;
    display: flex;
    align-items: center;
    .siteLogo {
      display: inline-block;
      img {
        width: 200px;
        height: auto;
      }
    }
    .header-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .menu-icon {
        font-size: larger;
        display: none;
      }
    }
  }
  .nav-menu {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    li {
      list-style: none;
      height: 100%;
      &:hover {
        .dropdown-menu {
          display: block;
        }
      }
      a {
        font-size: 0.8125rem;
        display: block;
        width: 100%;
        height: 100%;
        color: var(--primary);
        padding: 0.5rem;
        &:hover {
          color: var(--secondary);
        }
        &.active {
          color: var(--secondary);
        }
      }
      .dropdown-menu {
        min-width: 100px;
        position: absolute;
        top: 3.5rem;
        list-style: none;
        text-align: start;
        background-color: white;
        display: none;
        li {
          cursor: pointer;
          &:hover {
            background-color: var(--fourthColor);
          }
          a {
            font-size: 0.8125rem;
            display: block;
            width: 100%;
            height: 100%;
            color: var(--primary);
            padding: 0.5rem;
            &:hover {
              color: var(--secondary);
            }
            &.active {
              color: var(--secondary);
            }
          }
        }
        &.clicked {
          display: none;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .nav-menu {
    li {
      a:not(:last-child) {
        padding: 0.5rem 1rem !important;
      }
    }
  }
  .dropdown-menu {
    min-width: 200px !important;
    li {
      a {
        padding: 1rem !important;
      }
    }
  }
}

@media screen and (min-width: 950px) {
  .main-header {
    padding: 0 1rem !important;
  }
}

@media screen and (max-width: 949px) {
  .menu-icon {
    display: block !important;
    i {
      font-size: 2rem;
    }
  }
  .header-right {
    .nav-menu {
      position: absolute;
      flex-direction: column;
      width: 100%;
      left: -100%;
      top: 80px;
      &.active {
        background: white;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
      }
      .dropdown-menu {
        position: static !important;
        min-width: 200px !important;
      }
    }
  }
}
