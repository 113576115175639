.inner-banner {
  background-image: url("../../assets/images/banner-innerpage.jpg");
  padding: 2rem 0;
}

.inner-banner-caption {
  .filter-form-block {
    width: 100%;
    background-color: #fff;
    padding: 20px 15px;
    position: relative;

    &::after {
      width: 12px;
      height: 12px;
      content: "";
      border-bottom: 12px solid #fff;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      position: absolute;
      top: -12px;
      left: 0;
      right: 0;
      margin: auto;
    }

    .items-center {
      align-items: center;
    }

    h6 {
      font-size: 15px;
      font-weight: 400;
      color: var(--primary);
      margin: 0;
      line-height: 20px;
    }

    .cl-select {
      .form-select {
        font-size: 13px;
        border-radius: 0px;
      }
    }

    .filter-search {
      width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.innerpage-contents {
  background-color: var(--sixthColor);

  .cl-properties {
    h6 {
      font-size: 13px;
      color: var(--secondary);
      text-transform: uppercase;
      line-height: 18px;
      margin-bottom: 0;
    }

    h4 {
      font-size: 36px;
      color: var(--thirdColor);
    }

    .cl-properties-list {
      margin-top: 20px;

      .cl-property {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 20px;
        background-color: #fff;
        transition: all 0.7s ease;

        &:hover {
          box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
          transform: scale(1.05);
        }

        .cl-property-description {
          background-color: #fff;
          padding: 12px 15px;
          position: relative;

          h5 {
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 5px;
          }

          p {
            font-size: 12px;
            margin-bottom: 0;
            color: var(--primary);

            i {
              margin-right: 3px;
            }
          }

          .p-status {
            width: auto;
            height: 36px;
            padding-left: 10px;
            padding-right: 10px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: #f2f2f2;

            .status {
              font-size: 13px;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;

              span {
                margin-right: 5px;

                &.bullet-icon {
                  position: relative;
                  top: -1px;

                  i {
                    font-size: 10px;
                  }
                }
              }
            }

            &::after {
              width: auto;
              height: auto;
              content: "";
              border-bottom: 36px solid var(--fourthColor);
              border-right: 0px solid transparent;
              border-left: 30px solid transparent;
              position: absolute;
              top: 0px;
              right: 100%;
            }

            &.Live-status {
              background-color: var(--secondary);

              &::after {
                border-bottom-color: var(--secondary);
              }
            }

            &.Close-status {
              background-color: #767676;

              &::after {
                border-bottom-color: #767676;
              }
            }
          }
        }

        .cl-property-img {
          width: 100%;
          height: 180px;
          overflow: hidden;
          position: relative;

          img {
            width: 100%;
            height: 100%;
          }

          .invest-amt {
            width: 50%;
            background-color: #fff;
            padding: 10px 15px;
            position: absolute;
            left: 0;
            bottom: 0;
            border-radius: 0 4px 0 0;
            background-color: rgba(0, 0, 0, 0.7);

            h6 {
              font-size: 13px;
              color: #fff;
              position: relative;
              top: 0;
            }
          }
        }

        .cl-property-specification {
          width: 100%;

          .cl-p-short-desc {
            background-color: #f2f2f2;
            padding: 10px 15px;

            p {
              font-size: 12px;
              margin-bottom: 0px;
              line-height: 18px;
            }
          }

          .cl-p-spec {
            padding: 15px;
            padding-top: 0;

            ul {
              width: 100%;
              list-style: none;
              padding: 0;
              margin-bottom: 0;
              padding-top: 2px;

              li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 4px 0;
                font-size: 13px;
                border-bottom: 1px solid transparent;

                .spec-name {
                  color: var(--primary);
                  text-transform: uppercase;
                  font-size: 10px;
                }

                .spec-qty {
                  color: var(--primary);
                  
                }
              }

              // &.list-border {
              //     li {
              //         border-bottom: 1px solid #bfbfbf;
              //     }
              // }
              &.list-bg-color {
                li {
                  padding-left: 10px;
                  padding-right: 10px;

                  &:nth-child(even) {
                    background-color: #f2f2f2;
                  }
                }
              }
            }

            .detailsPara{
              text-align: justify;
            }
          }

          .btn-view-details {
            text-align: center;

            .btn-moreinfo {
              width: calc(100% - 30px);
              display: inline-block;
              padding-top: 10px;
              padding-bottom: 10px;
              background-color: var(--primary);
              border: 1px solid var(--primary);
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .cl-investnow {
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: var(--primary);
    padding: 30px 0;

    img {
      width: 100%;
      height: 100%;
    }

    // .dark-overlay {
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     top: 0;
    //     bottom: 0;
    //     z-index: 1;
    //     background-color: rgba(0, 0, 0, 0.8);
    // }

    .invest-content {
      // width: 100%;
      // height: 100%;
      // display: flex;
      // text-align: center;
      // position: absolute;
      // top: 0;
      // bottom: 0;
      // left: 0;
      // right: 0;
      // align-items: center;
      // z-index: 2;
      text-align: center;

      h3 {
        font-size: 24px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 10px;
      }

      h6 {
        .cl-btn-invest {
          font-size: 14px;
          font-weight: 400;
          text-transform: none;
          padding: 10px 20px;
        }
      }
    }
  }
}

.innerpage-container {
  width: 100%;

  // min-height: 68vh;
  .comingsoon {
    img {
      width: 55%;
    }

    text-align: center;
  }
}

// .cl-properties {
//   h6 {
//     font-size: 13px;
//     color: var(--secondary);
//     text-transform: uppercase;
//     line-height: 18px;
//     margin-bottom: 0;
//     font-weight: 600;
//   }

//   .cl-properties-item {
//     border: 2px solid var(--sixthColor);
//     .statusBar {
//       font-size: 13px;
//       color: var(--secondary);
//       background-color: var(--fifthColor);
//       font-weight: 600;
//     }
//     .points {
//       list-style: none;

//       .point {
//         border: 2px solid var(--fifthColor);
//         text-align: center;
//         h6{
//           color: var(--primary);
          
//         }
//       }
//     }
//     .details{
//       text-align: justify;
//     }
//     .detailsBtn{
//       background-color: var(--fifthColor);
//       display: flex;
//       justify-content: center;
      
//     }
//   }

//   .cl-property-img {
//     width: 100%;
//     height: 180px;
//   }
// }
