.main-footer {
  position: relative;
  // z-index: 999;
  .upper-footer {
    background-color: var(--fifthColor);
    border: 1px solid var(--sixthColor);
    padding: 2rem 1rem;
    .footer-logo {
      img {
        max-width: 60%;
      }
      p {
        color: var(--primary);
        font-weight: 400;
        font-size: 0.875rem;
      }
    }
    .footer-nav {
      h5 {
        font-size: 14px;
        text-transform: uppercase;
      }
      ul {
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          a {
            font-size: 13px;
            color: var(--seventhColor);
            padding: 5px 0;
            display: inline-block;
            transition: all 0.7s ease;
            &:hover {
              color: var(--primary);
              transform: translateX(4px);
            }
            &.active {
              color: var(--secondary);
            }
          }
        }
      }
    }
  }
  .lower-footer {
    background-color: var(--primary);
    padding: 2rem 1rem;
    .social-icon {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      padding: 0 5rem;
      li {
        list-style: none;
        background-color: white;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: all 0.8s ease;
        a {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--primary);
          font-size: 20px;
        }
        &:hover {
          transform: translateY(-4px);
        }
      }
    }
    .terms-links {
      border-bottom: 1px solid var(--seventhColor);
      ul {
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          a {
            text-decoration: underline;
            color: white;
            font-size: 14px;
          }
        }
      }
    }
  }
}
