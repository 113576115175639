.LoginRegister-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--primary);
  .main-box {
    max-width: 500px;
    width: 100%;
    padding: 0 1rem;
    .main-box-logo {
      a {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
        img {
          max-width: 45%;
        }
      }
    }
    .main-box-inner {
      padding: 2rem;
      border-radius: 6px;
    }
  }
}
